import { defineStore } from "pinia";
import type { Pagination } from "@tengiva/services-api-layer/types/pagination";
import type { Response } from "@tengiva/services-api-layer/types/response";
import type { filters } from "@/types/filters";
import type { CatalogTextileItem } from "@/types/products";

export const useCatalogStore = defineStore("catalog", () => {
	const { orderOptions } = storeToRefs(useFiltersStore());
	const { formatFiltersForEndpoint, formatFiltersForQueryParams } = useFiltersStore();

	const { listProductsForMktplcMarketplace } = usePMApi();
	const { $sentry } = useNuxtApp();

	const platformStore = usePlatformStore();
	const { plfm } = storeToRefs(platformStore);

	const router = useRouter();

	const products = ref<{ textile_items: CatalogTextileItem[]; pagination: Pagination }>();

	async function setProducts(platformOrgId: string, page: number = 1) {
		const response = await fetchProducts(platformOrgId, page);

		products.value = {
			textile_items: response?.textile_items || [],
			pagination: response?.pagination,
		};
	}

	const order = ref<string>("");

	function setOrder(o: string) {
		order.value = o;
	}

	const filters = ref<filters>({});

	function setFilters(newFilters: filters) {
		filters.value = newFilters;

		router.push({ query: formatFiltersForQueryParams(filters.value) });
	}

	async function fetchProducts(platformOrgId: string, page: number) {
		try {
			const selectedOrder = orderOptions.value?.find(option => option.value === order.value);
			const { response } = (await listProductsForMktplcMarketplace(platformOrgId, {
				limit: plfm.value.isEditorial ? 21 : 25,
				page,
				order: selectedOrder?.order || {
					"platform_catalog_products.created": "DESC",
				},
				filters: {
					...formatFiltersForEndpoint(filters.value),
				},
			})) as Response<{
				[key: string]: any;
			}>;

			if (response?.data) return response?.data;
		} catch (error) {
			$sentry.captureException(`Cannot fetch newest products for marketplace, ${error}`);
		}
	}

	return {
		products,
		order,
		filters,
		setProducts,
		setOrder,
		setFilters,
	};
});
